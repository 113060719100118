import {getLodop} from "@/print/LodopFuncs";

export default {
  printPaper: function (LODOP, data) {
    LODOP.PRINT_INIT('收银小票') //首先一个初始化语句
    LODOP.SET_PRINT_PAGESIZE(3, '75mm', '10mm', '收银小票')
    // LODOP.SET_PRINT_MODE('FULL_HEIGHT_FOR_OVERFLOW', true)
    let height = 0

    //门店信息
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '0mm',
      '75mm',
      '4mm',
      '衣物' + data.title + '单' + '(' + data.factoryName + ')',
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 14)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    //线
    height = height + 7
    LODOP.ADD_PRINT_LINE(height + 'mm', 0, height + 'mm', '75mm', 3, 0)

    height = height + 4
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '0mm',
      '75mm',
      '3mm',
      '流水号：' + data.serialNum,
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
    height = height + 5
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '0mm',
      '75mm',
      '3mm',
      data.title + '门店：' + data.storeName,
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
    if (data.flag == 1) {
      height = height + 5
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '75mm',
        '3mm',
        data.title + '时间：' + data.sendWashTime,
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
    } else {
      height = height + 5
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '75mm',
        '3mm',
        data.title + '时间：' + getTime(),
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
    }

    height = height + 5
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '0mm',
      '75mm',
      '3mm',
      '操作员：' + data.storeUserName,
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
    // height = height + 5;
    // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "送洗衣物：" + data.dataLength);
    // LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);

    //衣物列表：名称 服务 件数 原价 实收
    if (data.dataLength > 0) {
      height = height + 6
      LODOP.ADD_PRINT_TEXT(height + 'mm', '0mm', '11mm', '4mm', '序号')
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 11)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      LODOP.ADD_PRINT_TEXT(height + 'mm', '11mm', '26mm', '4mm', '衣物名称')
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 11)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      LODOP.ADD_PRINT_TEXT(height + 'mm', '26mm', '38mm', '4mm', '衣物号')
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 11)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      LODOP.ADD_PRINT_TEXT(height + 'mm', '38mm', '50mm', '4mm', '服务')
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 11)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      height = height + 6
    }

    //衣物列表
    for (let i = 0; i < data.data.length; i++) {
      let clothes = data.data[i]
      LODOP.ADD_PRINT_TEXT(
        height + i * 4 + 1 + 'mm',
        '0mm',
        '11mm',
        '4mm',
        i + 1,
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.ADD_PRINT_TEXT(
        height + i * 4 + 1 + 'mm',
        '11mm',
        '26mm',
        '4mm',
        clothes.clothesName,
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.ADD_PRINT_TEXT(
        height + i * 4 + 1 + 'mm',
        '26mm',
        '38mm',
        '4mm',
        clothes.outClothesNumber
          ? clothes.outClothesNumber
          : clothes.outClothesNum,
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      if (clothes.ifRewash) {
        LODOP.ADD_PRINT_TEXT(
          height + i * 4 + 1 + 'mm',
          '38mm',
          '50mm',
          '4mm',
          clothes.clothesServiceName + '(返)',
        )
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
        height = height + 2
      } else {
        LODOP.ADD_PRINT_TEXT(
          height + i * 4 + 1 + 'mm',
          '38mm',
          '50mm',
          '4mm',
          clothes.clothesServiceName,
        )
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
        height = height + 2
      }
    }

    height = height + data.data.length * 4 + 1
    //线
    height = height + 2
    LODOP.ADD_PRINT_LINE(height + 'mm', 0, height + 'mm', '75mm', 3, 0)
    //送洗数据
    if (data.title == '送洗') {
      height = height + 5
      LODOP.ADD_PRINT_TEXT(height + 'mm', '0mm', '75mm', '4mm', '送洗数据')
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      height = height + 6
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '35mm',
        '3mm',
        '总送洗件数：' + data.dataLength + '件',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '35mm',
        '75mm',
        '3mm',
        '附件件数：' + data.enclosureNum + '件',
      )
      height = height + 5
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '35mm',
        '3mm',
        '加急件数：' + data.urgentNum + '件',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '35mm',
        '75mm',
        '3mm',
        '单烫件数：' + data.singleNum + '件',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      height = height + 5
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '35mm',
        '3mm',
        '返洗件数：' + data.backWashNum + '件',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
    }

    if (data.title == '送洗') {
      //物流数据
      height = height + 6
      LODOP.ADD_PRINT_TEXT(height + 'mm', '0mm', '75mm', '4mm', '物流数据')
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      height = height + 6
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '35mm',
        '3mm',
        '灰色收纳袋：' + data.logistics.grayBag + '袋',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '35mm',
        '75mm',
        '3mm',
        '黑色收纳袋：' + data.logistics.blackBag + '袋',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      height = height + 5
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '0mm',
        '35mm',
        '3mm',
        '蓝色收纳箱：' + data.logistics.blueBag + '箱',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '35mm',
        '75mm',
        '3mm',
        '其他收纳袋：' + data.logistics.otherBag + '袋',
      )
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
      height = height + 7
      LODOP.ADD_PRINT_TEXT(height + 'mm', '0mm', '75mm', '4mm', '店长说明')
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 14)
      LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
      if (data.remark.length > 25) {
        height = height + 8
        LODOP.ADD_PRINT_TEXT(
          height + 'mm',
          '0mm',
          '75mm',
          '4mm',
          data.remark.substring(0, 20),
        )
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        height = height + 5
        LODOP.ADD_PRINT_TEXT(
          height + 'mm',
          '0mm',
          '75mm',
          '4mm',
          data.remark.substring(20, 40),
        )
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        height = height + 5
        LODOP.ADD_PRINT_TEXT(
          height + 'mm',
          '0mm',
          '75mm',
          '4mm',
          data.remark.substring(40),
        )
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        height = height + 5
      } else {
        height = height + 8
        LODOP.ADD_PRINT_TEXT(height + 'mm', '0mm', '75mm', '4mm', data.remark)
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        height = height + 5
      }
    }
    // height = height + data.data.length * 4 + 2;
    // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "3mm", "洗衣通，让洗衣更简单~");
    // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
    // LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
    // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
    LODOP.SET_PRINTER_INDEX(this.getPrintIndex(LODOP,1))
    // LODOP.PRINT_DESIGN();
    LODOP.PRINT()
  },
  //物流小票
  logisticsReceipt: function(LODOP,data){
    LODOP.PRINT_INIT('包裹送洗单') //首先一个初始化语句
    LODOP.SET_PRINT_PAGESIZE(3, '50mm', '3mm', '包裹送洗单')

    let height = 4
    //门店信息
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '0mm',
      '50mm',
      '4mm',
      '衣物送洗包裹'
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)


    //线
    height = height + 6
    LODOP.ADD_PRINT_LINE(height + 'mm', 0, height + 'mm', '75mm', 3, 0)
    LODOP.ADD_PRINT_BARCODE("14mm", "10mm", "33mm", "13mm", "128Auto", data.bundleNumber);//条码
    LODOP.SET_PRINT_STYLEA(0, "AlignJustify", 2);
    LODOP.SET_PRINT_STYLEA(0,"FontSize",8)
    // LODOP.ADD_PRINT_RECT(108,4,170,150,0) //边框
    height = height +  21
    let heightTwo = height + 3
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '-3mm',
      '25mm',
      '4mm',
      '出发站点：'
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    LODOP.ADD_PRINT_LINE(heightTwo + 'mm', '15mm', heightTwo + 'mm', '44mm')
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '17mm',
      '120mm',
      '4mm',
      data.storeName
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)


    height = height +  7
    heightTwo = height + 4
    //门店信息
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '-3mm',
      '25mm',
      '4mm',
      '目的站点：',
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    LODOP.ADD_PRINT_LINE(heightTwo + 'mm', '15mm', heightTwo + 'mm', '44mm')
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '17mm',
      '120mm',
      '4mm',
      data.factoryName
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)


    height = height +  7
    heightTwo = height + 4
    //门店信息
    LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '-3mm',
        '25mm',
        '4mm',
        '包裹颜色：',
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    LODOP.ADD_PRINT_LINE(heightTwo + 'mm', '15mm', heightTwo + 'mm', '44mm')
    LODOP.ADD_PRINT_TEXT(
        height + 'mm',
        '17mm',
        '120mm',
        '4mm',
        data.colorUnitName
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    height = height +  7
    heightTwo = height + 4
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '-3mm',
      '25mm',
      '4mm',
      '衣物件数：'
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    LODOP.ADD_PRINT_LINE(heightTwo + 'mm', '23mm', heightTwo + 'mm', '15mm')
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '17mm',
      '120mm',
      '4mm',
      data.clothesCount
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    height = height +  7
    heightTwo = height + 4

    LODOP.ADD_PRINT_TEXT(
        height - 7 + 'mm',
        '18mm',
        '25mm',
        '4mm',
        '附件件数：'
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    //线
    LODOP.ADD_PRINT_LINE(heightTwo -7 + 'mm', '36mm', heightTwo - 7 + 'mm', '44mm')
    //值
    LODOP.ADD_PRINT_TEXT(
        height - 7 + 'mm',
        '37mm',
        '120mm',
        '4mm',
        data.accessoryCount
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    //两行展示在一起，减掉一行的高度
    height = height -  7
    heightTwo = height - 4

    height = height +  7
    heightTwo = height + 4
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '-3mm',
      '25mm',
      '4mm',
      '打包时间：',
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    LODOP.ADD_PRINT_LINE(heightTwo + 'mm', '15mm', heightTwo + 'mm', '44mm')
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '17mm',
      '120mm',
      '4mm',
      data.packTime
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    height = height +  7
    heightTwo = height + 4
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '-3mm',
      '28mm',
      '4mm',
      '操作人：',
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)
    LODOP.ADD_PRINT_LINE(heightTwo + 'mm', '15mm', heightTwo + 'mm', '44mm')
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '17mm',
      '120mm',
      '4mm',
      data.userName
    )
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    height = height +  7
    LODOP.ADD_PRINT_TEXT(
      height + 'mm',
      '5mm',
      '40mm',
      '4mm',
      '洗衣通 400 816 5008',
    )
    LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 8)
    LODOP.SET_PRINT_STYLEA(0, 'Bold', 1)

    LODOP.SET_PRINTER_INDEX(this.getPrintIndex(LODOP,3))
    // LODOP.PRINT_DESIGN();
    LODOP.PRINT()

  },

  getPrintIndex: function(LODOP,type) {
    console.log(LODOP)
    let defaultName;
    let printIndex;
    LODOP.SET_LICENSES(
        "",
        "660FDFD7D9F7F7101A272F2EB57C306A",
        "C94CEE276DB2187AE6B65D56B3FC2848",
        ""
    );

    let arr = [];
    let iPrinterCount = LODOP.GET_PRINTER_COUNT();
    for (let i = 0; i < iPrinterCount; i++) {
      arr.push({
        label: LODOP.GET_PRINTER_NAME(i),
        value: i,
      });
    }
    let labelList = [];
    if (type === 1) {
      //小票
      defaultName = LODOP.GET_PRINTER_NAME("-1"); //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
      arr.forEach((item) => {
        if (item.label === defaultName) {
          printIndex = item.value;
        }
      });
    } else if (type === 2) {
      //水洗唛
      labelList.push("TSC");
      labelList.push("水洗唛打印机");
      labelList.push("HPRT XT300");
      labelList.push("HPRT XT300 - ZPL");
      arr.forEach((item) => {
        for (let i = 0; i < labelList.length; i++) {
          if (item.label === labelList[i]) {
            console.log(labelList[i]);
            printIndex =  item.value;
          }
        }
      });
    } else {
      //包裹号不干贴
      labelList.push("LNS");
      labelList.push("Gprinter GP-3120TL");
      labelList.push("2120TU(标签)");
      arr.forEach((item) => {
        for (let i = 0; i < labelList.length; i++) {
          if (item.label === labelList[i]) {
            console.log(labelList[i]);
            printIndex = item.value;
          }
        }
      });
    }
    return printIndex;
  },


}

export function getTime() {
  var date1 = new Date()
  var year = date1.getFullYear()
  var month = date1.getMonth() + 1
  var day = date1.getDate()
  var hours = date1.getHours()
  var minutes = date1.getMinutes()
  var seconds = date1.getSeconds()
  return (
    year +
    '年' +
    month +
    '月' +
    day +
    '日 ' +
    hours +
    ':' +
    minutes +
    ':' +
    seconds
  )
}
